<!-- Mto particular de Miscelaneas -->

<template>
  <div class="misc_M" v-if="schema">
    <v-sheet :elevation="4">

      <!-- Cabecera -->
      <div class="cabecera">
        <base_Header
          v-if="Entorno.header.header"       
          :Entorno="Entorno.header"
          @onEvent="event_Header">
        </base_Header>
      </div> 
      
      <div class="contenedor" style="width:1200px">
        <!-- Botones Mto -->
        <div class="conflex" style="justify-content:space-between;width:320px">
          <!-- Mto -->            
          <btramto
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="m"
              :estado="estado"
              @onEvent="event_btra_Mto">        
          </btramto>
          
          <!-- Ventana de Documentos -->
          <common_Docs
            :padre="stName"
            :id="record.id" 
            show="1"
            tip="3">
          </common_Docs>

          <!-- Ventana de Facturas -->
          <common_Fras
            :padre="stName"
            :id="record.id"       
            show="1"
            tip="3">
          </common_Fras>       
        </div>

        <!-- Controles del Mto -->
        <div class="conflex" style="padding-top:10px">      
          <div class="columna" style="width:35%">
            <v-sheet v-bind="$cfg.styles.marco">
              <div class="conflex">
                <v-select
                  style="flex: 1 1 80%"
                  v-bind="$select"
                  v-model="schema.ctrls.tipo.value"
                  :items="$store.state.datos_iniciales.misctipo"
                  :disabled="!is_edit"
                  item-value="id"
                  item-text="name">
                </v-select>

                <v-checkbox
                  style="flex: 1 1 20%;padding-left:10px"
                  v-bind="$checkbox"
                  v-model="schema.ctrls.baja.value"
                  :label="schema.ctrls.baja.label"
                  :disabled="!is_edit">
                </v-checkbox>
              </div>

              <ctrlcontact :schema="schema" :edicion="is_edit"> </ctrlcontact>
            </v-sheet>
          
            
            <div class="cab">DATOS DE CONTACTO</div>
            <v-sheet v-bind="$cfg.styles.marco">
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.cont1.value"
                :label="schema.ctrls.cont1.label"
                :disabled="!is_edit">
              </v-text-field>
            
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.tlf1.value"
                :label="schema.ctrls.tlf1.label"
                :disabled="!is_edit">
              </v-text-field>
            
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.tlf2.value"
                :label="schema.ctrls.tlf2.label"
                :disabled="!is_edit">
              </v-text-field>
             
              <div style="conflex">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.movil.value"
                  :label="schema.ctrls.movil.label"
                  :disabled="!is_edit">
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.fax1.value"
                  :label="schema.ctrls.fax1.label"
                  :disabled="!is_edit">
                </v-text-field>
              </div>

              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.email1.value"
                :label="schema.ctrls.email1.label"
                :disabled="!is_edit">
              </v-text-field>
           
              <ctrlurl 
                style="flex: 0 0 100%"
                :schema="schema.ctrls.url" 
                :edicion="is_edit"> 
              </ctrlurl>             
            </v-sheet>
          </div>

          <div class="columna" style="width:30%">
            <div class="cab">ADMINISTRACIÓN</div>
            <v-sheet v-bind="$cfg.styles.marco">
              <!-- <div class="columna"> -->
                <div style="conflex">
                  <v-checkbox
                    style="flex: 1 1 50%"
                    v-bind="$checkbox"
                    v-model="schema.ctrls.cvarios.value"
                    :label="schema.ctrls.cvarios.label"
                    :disabled="!is_edit">
                  </v-checkbox>
                  
                  <v-text-field
                    style="flex: 1 1 50%"
                    v-bind="$input"
                    v-model="schema.ctrls.razon.value"
                    :label="schema.ctrls.razon.label"
                    :disabled="!is_edit">
                  </v-text-field>
                </div>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.admoncont.value"
                  :label="schema.ctrls.admoncont.label"
                  :disabled="!is_edit">
                </v-text-field>
              
                <div style="conflex">
                  <v-text-field
                    style="flex: 1 1 50%"
                    v-bind="$input"
                    v-model="schema.ctrls.admontlf1.value"
                    :label="schema.ctrls.admontlf1.label"
                    :disabled="!is_edit">
                  </v-text-field>

                  <v-text-field
                    style="flex: 1 1 50%"
                    v-bind="$input"
                    v-model="schema.ctrls.admonfax1.value"
                    :label="schema.ctrls.admonfax1.label"
                    :disabled="!is_edit">
                  </v-text-field>
                </div>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.admonemail.value"
                  :label="schema.ctrls.admonemail.label"
                  :disabled="!is_edit">
                </v-text-field>

                <div style="conflex">
                  <v-text-field
                    style="flex: 0 0 33%"
                    v-bind="$input"
                    v-model="schema.ctrls.ban.value"
                    :label="schema.ctrls.ban.label"
                    :disabled="!is_edit">
                  </v-text-field>
                
                  <v-text-field
                    style="flex: 0 0 33%"
                    v-bind="$input"
                    v-model="schema.ctrls.cta.value"
                    :label="schema.ctrls.cta.label"
                    :disabled="!is_edit">
                  </v-text-field>
    
                  <v-text-field
                    style="flex: 0 0 33%"
                    v-bind="$input"
                    v-model="schema.ctrls.vto.value"
                    :label="schema.ctrls.vto.label"
                    :disabled="!is_edit">
                  </v-text-field>
                </div>
              <!-- </div> -->
            </v-sheet>
          </div>

          <div class="columna"  style="width:35%">
            <div class="cab">COMPAÑÍA</div>
            <v-sheet v-bind="$cfg.styles.marco">
              <div>
                <ctrlfinder 
                  style="flex: 0 0 100%"
                  finderName="ciac_F" 
                  :schema="schema.ctrls.cia_id" 
                  :edicion="is_edit">
                </ctrlfinder>
              </div>

              <ctrlobs3 
                style="flex: 0 0 100%"
                :schemaComponente="schema.ctrls.obs"
                :schema="schema"
                :record="record"
                :edicion="is_edit">           
              </ctrlobs3>
            
              <ctrlobs3 
                style="flex: 0 0 100%"
                :schemaComponente="schema.ctrls.obs2"
                :schema="schema"
                :record="record"
                :edicion="is_edit">   
              </ctrlobs3>
            </v-sheet>

            <div class="conflex">
              <div class="columna">
                <div class="cab">RELACIÓN COMERCIAL</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <v-checkbox
                    v-bind="$checkbox"
                    v-model="schema.ctrls.proveedor.value"
                    :label="schema.ctrls.proveedor.label"
                    :disabled="!is_edit">
                  </v-checkbox>

                  <v-checkbox
                    v-bind="$checkbox"
                    v-model="schema.ctrls.cliente.value"
                    :label="schema.ctrls.cliente.label"
                    :disabled="!is_edit">
                  </v-checkbox>
                </v-sheet>
              </div>

              <div class="columna">
                <div class="cab">TIPO DE PERSONA</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <v-radio-group v-model="schema.ctrls.persona.value" row>
                    <v-radio
                      v-for="tipo in tipo_persona"
                      :key="tipo.id"
                      :label="tipo.label"
                      :value="tipo.id"
                      :disabled="!is_edit">
                    </v-radio>
                  </v-radio-group>
                </v-sheet>
              </div>
            </div>
    
            <div class="cab">TIPO DE RELACIÓN</div>
            <v-sheet v-bind="$cfg.styles.marco">
              <v-radio-group v-model="schema.ctrls.residencia.value" row>
                <v-radio
                  v-for="tipo in tipo_residencia"
                  :key="tipo.id"
                  :label="tipo.label"
                  :value="tipo.id"
                  :disabled="!is_edit">
                </v-radio>
              </v-radio-group>

              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.ccont.value"
                :label="schema.ctrls.ccont.label"
                :disabled="!is_edit">
              </v-text-field>

               <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.pdi.value"
                :label="schema.ctrls.pdi.label"
                :disabled="!is_edit">
              </v-checkbox>

            </v-sheet>           
          </div>
          
        </div>
      </div>
    </v-sheet>
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btramto = () => plugs.groute("btramto_new.vue", "comp");
  /* const btraextra = () => plugs.groute("btraextra.vue", "comp"); */
  const ctrlcontact = () => plugs.groute("ctrlcontact.vue", "comp");
  const ctrlurl = () => plugs.groute("ctrlurl.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
  const common_Docs = () => plugs.groute("common_Docs.vue", "comp");
  const common_Fras = () => plugs.groute("common_Fras.vue", "comp");

  export default {
    mixins: [mixinMto],
    components: {
      base_Header,
      btramto,
      common_Docs,
      common_Fras,
      ctrlcontact,
      ctrlurl,
      ctrlfinder,
      ctrlobs3
    },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}},
      disparo: { type:Boolean, default:false },
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'misc_M',
        stName:'stMmisc',
        tipo_persona:[],
        tipo_residencia: []
      };
    },

    methods: {
      ini_data(){
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;        
        
        // configuro Entorno 
        this.Entorno.header.titulo = "Mantenimiento Miscelaneas"; 
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));       
        this.Entorno.btra.footer.extra = [
          { accion: "view_facturas", btn: "btn_facturas", show: 1 },
          { accion: "view_docs", btn: "btn_docs", show: 1 }
        ];

        // items adicionales
        this.tipo_persona= [
          { id: "0", label: "FÍSICA" },
          { id: "1", label: "JURÍDICA" }
        ];

        this.tipo_residencia= [
          { id: "0", label: "RESIDENTE" },
          { id: "1", label: "UNIÓN EUROPEA" },
          { id: "2", label: "EXTRANJERO" }
        ]

      },


    }
  };
</script>
